<template>
  <div class="mtitle">
    <div
      :class="item.path == $route.path ? 'title isclick' : 'title'"
      v-for="(item, index) in titleListNew"
      :key="item.path"
      @mousedown="mousedown(item.name, $event)"
      :ref="item.name"
    >
      <!-- <span @click="jumppage(item)">{{item.name}}</span> -->
      <div class="link" @click="jumpPage(item.path)">{{ item.name }}</div>
      <i class="el-icon-circle-close" @click="deltitleItem(index)"></i>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  inject: ["changerefresh"],
  props: ["titlelist"],
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    ...mapState(["titleListNew"]),
  },
  methods: {
    ...mapMutations(["delTitleList"]),
    jumpPage(path) {
      if (path && this.$route.path != path) this.$router.push(path);
      this.changerefresh();
    },
    mousedown(name, event) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout((_) => {
        // console.log(this.$refs[name])
        this.$refs[name].style = "cursor:move";
      }, 1000);
    },
    deltitleItem(index) {
      index - 1 > 0
        ? this.$router.push(this.titleListNew[index - 1].path)
        : this.$router.push(
            this.titleListNew[this.titleListNew.length - 1].path
          );
      this.delTitleList(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.mtitle {
  overflow: auto;
  line-height: 30px;
  height: 35px;
  color: #333333;
  background: #f0f0f0;
  .link {
    cursor: inherit;
    text-decoration: none;
    color: #333333;
  }
  .navtitle_span {
    margin-right: 1%;
    vertical-align: middle;
  }
  .title {
    position: relative;
    display: inline-block;
    width: 100px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 2px;
    vertical-align: middle;
    box-shadow: 0 2px 3px 0px #b0afaf;
    cursor: default;
    &:hover {
      background-color: #2b56bf;
      .link {
        color: #ffffff;
      }
    }
    .el-icon-circle-close {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 15px;
      cursor: pointer;
    }
    &:hover .el-icon-circle-close {
      display: inline-block;
      color: rgb(255, 255, 255);
    }
  }
  .isclick {
    box-shadow: 0 2px 3px 0px #b0afaf, 0 0 3px 0px #2b56bf inset;
  }
}
</style>
