<template>
  <div class="rolepeople">
    <div class="rolepeople_content">
      <div class="table_left">
        <div class="southcargo_bot_title">
          <span>角色名称查询</span>
          <button @click="addInfo">添加角色</button>
        </div>
        <div class="tab_box" style="border:none">
          <el-table
            :data="rolelist.list"
            border
            @row-click="leftRowDbclick"
            style="width: 100%; height: 80%"
            
          >
             <el-table-column  show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
            <el-table-column  show-overflow-tooltip prop="roleName" label="角色名称">
            </el-table-column>
            <el-table-column  show-overflow-tooltip prop="remark" label="用户类型"> </el-table-column>
            <el-table-column  show-overflow-tooltip label="状态">
              <template v-slot="res">
                <span v-if="res.row.status === 1" style="color: #13d168"
                  >启用</span
                >
                <span v-if="res.row.status === 0" style="color: #fe5a24"
                  >禁用</span
                >
              </template>
            </el-table-column>
            <el-table-column  show-overflow-tooltip label="操作"> 编辑 </el-table-column>
          </el-table>
          <div class="paging" v-show="rolelist.list&&rolelist.list.length">
            <el-pagination
              :page-size="rolelistPage.limit"
              :pager-count="5"
              layout="total,prev, pager,next, jumper"
              :total="rolelist.totalCount"
              :current-page="rolelistPage.page"
              @current-change="leftCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="table_right">
        <div class="southcargo_bot_title">
          <span>用户查询</span>
        </div>
        <div class="tab_box" style="border:none">
          <el-table
          border
            :data="userlist"
            @row-click="rightRowDbclick"
            style="width: 100%"
          >
             <el-table-column  show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
            <el-table-column  show-overflow-tooltip prop="chineseName" label="用户名">
            </el-table-column>
            <el-table-column  show-overflow-tooltip prop="username" label="登录名"> </el-table-column>
            <el-table-column  show-overflow-tooltip prop="mobile" label="联系方式"> </el-table-column>
            <el-table-column  show-overflow-tooltip label="创建日期">
              <template v-slot="res">
                {{ mydateFormat(res.row.createTime, "时分秒") }}
              </template>
            </el-table-column>
            <el-table-column  show-overflow-tooltip label="状态">
              <template v-slot="res">
                <span
                  v-if="res.row.status === 1"
                  class="el_spanfontstyle"
                  style="color: #13d168"
                  >启用</span
                >
                <span
                  v-if="res.row.status === 0"
                  class="el_spanfontstyle"
                  style="color: #fe5a24"
                  >禁用</span
                >
              </template>
            </el-table-column>
            <el-table-column  show-overflow-tooltip label="操作">
              <span style="color: #ff5926">重置口令</span>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 添加角色对话框 -->
    <m-dialog :isShow="addrole" @changeState="sendMsg" :title="titleinfo">
      <div class="dialogItem">
        <span class="itemMsg">角色名称</span>
        <div class="itemIpt">
          <m-superIpt
            v-model.lazy="roleInfo.roleName"
            defaultInfo="请输入角色名称"
          ></m-superIpt>
        </div>
      </div>
      <div class="dialogItem">
        <span class="itemMsg">备注</span>
        <div class="itemIpt">
          <m-superIpt
            v-model.lazy="roleInfo.remark"
            defaultInfo="请输入角色名称"
          ></m-superIpt>
        </div>
      </div>
    </m-dialog>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import mDialog from "@/components/mDialog";
import mSuperIpt from "@/components/mSuperIpt";
export default {
  inject: ["alertDialog"],
  data() {
    return {
      listitem: [{ name: "角色管理" }, { name: "角色人员" }],
      titleinfo: "",
      gitem: null,
      rolelistPage: {
        page: 1,
        limit:15,
        noticeType: "",
      },
      adduse: false,
      addrole: false,
      roleInfo: {
        roleId: "",
        remark: "",
        roleName: "",
      },
      rolelist: [],
      userlist: [],
    };
  },
  components: {
    mTitle,
    mDialog,
    mSuperIpt,
  },
  created() {
    this.rolepeopleList();
  },
  methods: {
    async rolepeopleList() {
      const { data } = await this.$http("api1", "/sys/role/list", "get");
      if (!data || data.msg != "success")
        return this.$message.error("获取数据失败");
      this.rolelist = data.page;
    },
    async leftRowDbclick(item, column) {
      if (column.label === "角色名称" || column.label === "用户类型") {
        const { data } = await this.$http(
          "api1",
          "/sys/user/queryUserListByRoleId",
          "get",
          { role_id: item.roleId }
        );
        if (!data || data.msg != "success")
          return this.$message.error("获取数据失败");
        this.userlist = data.list;
        this.gitem = item.roleId;
        return null;
      }
      if (column.label === "操作") {
        this.roleInfo.roleId = item.roleId;
        this.roleInfo.remark = item.remark;
        this.roleInfo.roleName = item.roleName;
        this.titleinfo = "编辑角色";
        this.addrole = true;
      }
      if (column.label === "状态") {
        !item.status ? (item.status = 1) : (item.status = 0);
        const { data } = await this.$http(
          "api1",
          "/sys/role/updateStatus",
          "post",
          { roleId: item.roleId, status: item.status }
        );
        if (!data || data.msg != "success") return;
        this.$message.success("修改状态成功");
      }
    },
    async rightRowDbclick(item, column) {
      if (column.label === "状态") {
        this.alertDialog("改操作将改变当前状态", async (_) => {
          !item.status ? (item.status = 1) : (item.status = 0);
          const { data } = await this.$http(
            "api1",
            "/sys/user/updateStatus",
            "post",
            { userId: item.userId, status: item.status }
          );
          if (!data || data.msg != "success") return;
          this.$message.success("修改状态成功");
        });
      }
      if (column.label === "操作") {
        const { data } = await this.$http(
          "api1",
          "/sys/user/defaultPassword",
          "post",
          { userId: item.userId }
        );
        if (!data || data.msg != "success") return;
        this.$message.success("重置口令成功");
      }
    },
    leftCurrentChange(item) {
      if (this.rolelist.totalCount <= this.rolelistPage.limit) return;
      this.rolelistPage.page = item;
      this.rolepeopleList();
    },
    addInfo() {
      this.roleInfo.roleId = "";
      this.roleInfo.remark = "";
      this.roleInfo.roleName = "";
      this.titleinfo = "添加角色";
      this.addrole = true;
    },
    async sendMsg(item) {
      if (item != "confirm") return (this.addrole = false);
      let apipath = "/sys/role/save";
      if (this.titleinfo == "编辑角色") apipath = "/sys/role/update";
      if (!this.roleInfo.roleName || !this.roleInfo.remark)
        return this.$message.error("必填项不能为空");
      const { data } = await this.$http("api1", apipath, "post", this.roleInfo);
      if (!data || data.msg != "success") return;
      this.$message.success(this.titleinfo + "成功");
      this.addrole = false;
      this.rolepeopleList();
    },
    sendUser(item) {
      if (item !== "confirm") return (this.adduse = false);
    },
  },
};
</script>
<style lang="scss" scoped>
.rolepeople {
  position: relative;
  height: 100%;
  .rolepeople_content {
    position: absolute;
    top: 30px;
    bottom: 0;
    width: 100%;
    background: #fff;
    .southcargo_bot_title {
      box-sizing: border-box;
      height: 45px;
      line-height: 45px;
      font-size: 80%;
      font-weight: bold;
      span {
        margin: 0 5px 0 10px;
      }
      button {
        width: 80px;
        height: 30px;
        background: #2b56bf;
        border-radius: 2px;
        border: none;
        color: #fff;
        margin-left: 20px;
      }
    }
    .table_left {
      float: left;
      position: relative;
      width: 34%;
      height: 100%;
      background: #fff;
    }
    .table_right {
      float: right;
      position: relative;
      width: 65%;
      height: 100%;
      background: #fff;
    }
    .tab_box {
      box-sizing: border-box;
      position: absolute;
      top: 45px;
      bottom: 0;
      border-top: 1px solid #f3f4f5;
      width: 100%;
      cursor: pointer;
    }
    .paging {
      position: absolute;
      width: 100%;
      bottom: 10%;
      transform: translateY(50%);
      height: 30px;
      text-align: center;
    }
  }
  .dialogItem {
    height: 30px;
    width: 100%;
    margin: 20px 0;
    .itemMsg {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 21%;
    }
    .itemIpt {
      float: right;
      font-size: 90%;
      height: 100%;
      width: 79%;
      background: #f3f4f5;
    }
  }
}
</style>