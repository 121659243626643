<template>
  <div v-if="isShow" class="mdialog">
    <div class="dialog_box" :style="{width:width?width:'30%'}">
      <div class="Title" :style="titlecenter ? 'text-align:center' : ''">
        {{ title }}
      </div>
      <div class="content">
        <slot></slot>
      </div>
      <div class="btn">
        <button
          v-if="showEdit"
          class="btnsty"
          style="background: #2b56bf"
          @click="switchHover('confirm')"
        >
          确定
        </button>
        <button
          class="btnsty"
          style="color: #FFF"
          @click="switchHover('close')"
        >
          关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    isShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    titlecenter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showEdit: {
      type: Boolean,
      default() {
        return true;
      },
    },
    width:{
      type:String,
      default(){
        return ""
      }
    }
  },
  methods: {
    switchHover(state) {
      this.$emit("changeState", state);
    },
  },
};
</script>
<style lang="scss" scoped>
.mdialog {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 80%;
  background: rgba(0, 0, 0, 0.5);
  .dialog_box {
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
    background: #fff;
    .Title,
    .btn {
      position: relative;
      box-sizing: border-box;
      padding: 0 30px;
      height: 45px;
      line-height: 45px;
      .btnsty {
        float: right;
        border: none;
        width: 80px;
        height: 30px;
        margin: 7.5px 0;
        background: #ff4900;
        color: #fff;
        border-radius: 2px;
        margin-left: 20px;
      }
    }
    .Title{
        border-bottom: 1px solid #f3f4f5;
    }
    .content {
      box-sizing: border-box;
      padding: 0 30px;
    }
  }
}
</style>