<template>
  <div class="msupeript">
    <input class="ipt" :type="type" :readonly="readonly" :value="value" @blur="checkVal"/>
    <div v-show="info&&!readonly" class="errorInfo">{{info}}</div>
  </div>
</template>
<script>
export default {
    props:{
        value:String,
        rule:{
            validator(val){
               let arr=["email","tel","card"];
               if(arr.includes(val)){
                   return true;
               }else{
                   return false;
               }
            }
        },
        type:{
            type:[String],
            default:"text"
        },
        defaultInfo:String,
        readonly:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            info:""
        }
    },
    methods:{
        checkVal(evet){
            var val=evet.target.value;
            if(!val){
                this.$emit("input",val);
                return this.info=this.defaultInfo;
            }
            else if(val&&!this.rule){
                this.info="";
                return this.$emit("input",val);
            }else{
                this[this.rule+"Fun"](val);
            }
        },
        emailFun(val){
            val=val.trim();
            if(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(val)){
                this.info="";
            }else{
                this.info="邮箱格式不正确";
            }
            this.$emit("input",val);
        },
        telFun(val){
            val=val.trim();
            if(/^1[23456789][0-9]{8,10}$/.test(val)){
                this.info="";
            }else{
                this.info="手机格式不正确";
            }
            this.$emit("input",val);
        },
        cardFun(val){
            val=val.trim();
            if(/^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test(val)){
                this.info="";
            }else{
                this.info="身份证号码格式不正确";
            }
            this.$emit("input",val);
        }
    }
};
</script>
<style lang="scss" scoped>
.msupeript {
  position: relative;
  width: 100%;
  height: 100%;
  .ipt {
    position: absolute;
    box-sizing: border-box;
    font-size: inherit;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
  }
  .errorInfo{
    position: absolute;
    width: 100%;
    color: red;
    transform: translateY(100%);
    bottom: 0;
  }
  &::before{
            content: "*";
            position: absolute;
            font-size: 90%;
            color: red;
            top: 50%;
            transform: translate(-200%,-50%);
        }
}
</style>